import React from "react";

import Grid from "@mui/material/Grid";
import { DateField, FunctionField, SimpleShowLayout, TextField } from "react-admin";

import { UserRoles } from "../../../core/providers/auth/roles";
import { ConnectionType, StoveModel, StoveStatus } from "../../../utils/commons";
import { countryNameRenderer, customerRenderer, enumRenderer } from "../../../utils/field-renderers";
import { useCheckAccess } from "../../../utils/use-check-access";
import { FullStove } from "../StoveShow";

export const StoveMainTab: React.FC = () => {
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <TextField source="id" label="Internal ID"/>
          <TextField source="nativeId" label="Stove UID"/>
          <TextField source="deviceId" label="Device ID"/>
          <FunctionField label="Model"
            render={(record: FullStove) => enumRenderer(record.model, StoveModel)}/>
          <FunctionField label="Type"
            render={(record: FullStove) => enumRenderer(record.connectionType, ConnectionType)}/>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <FunctionField label="Country"
            render={(record: FullStove) => countryNameRenderer(record) || "-"}/>
          <FunctionField label="Customer"
            render={(record: FullStove) => customerRenderer(record.customer) || "-"}/>
          <FunctionField label="Status"
            render={(record: FullStove) => enumRenderer(record.status, StoveStatus)}/>
          <DateField source="timeCreated" label="Registration time" showTime/>
          <FunctionField
            label="Repossession time"
            render={(record: FullStove) =>
              record.timeInactivated
                ? <DateField source="timeInactivated" showTime record={record} />
                : "-"
            }
          />
        </SimpleShowLayout>
      </Grid>
      {
        isAdmin && <Grid item xs={4}>
          <SimpleShowLayout>
            <FunctionField label="Nft Id"
              render={(record: FullStove) => record.nftId || "-"}/>
            <FunctionField label="Nft Collection Name"
              render={(record: FullStove) => record.nftCollectionName || "-"}/>
            <FunctionField label="Did"
              render={(record: FullStove) => record.did || "-"}/>
            <FunctionField label="Ixo Address"
              render={(record: FullStove) => record.ixoAddress || "-"}/>
          </SimpleShowLayout>
        </Grid>
      }
    </Grid>
  );
};
