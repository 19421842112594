import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import Co2Icon from "@mui/icons-material/Co2";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Box, Tooltip } from "@mui/material";
import moment from "moment";
import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField
} from "react-admin";

import { useCustomerFilters } from "../../components/filter/customer-filters";
import { stoveConnectionTypeFilter, useStoveFilters } from "../../components/filter/stove-filters";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import { ConnectionType, StoveModel, StoveStatus } from "../../utils/commons";
import {
  countryNameRenderer,
  customerRenderer,
  enumIconRenderer,
  enumRenderer
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { ImportDeviceDataButton } from "./bulk-actions/ImportDeviceDataButton";
import StoveBulkActions from "./bulk-actions/StoveBulkActions";
import { FullStove } from "./StoveShow";

const stoveStatusIcons: Record<keyof typeof StoveStatus, React.ReactElement> = {
  ACTIVE: <CheckIcon sx={{ fontSize: "18px" }} color={"success"}/>,
  INACTIVE: <CloseIcon sx={{ fontSize: "18px" }} color={"warning"}/>
};

const renderUnlockedUntil = (record: FullStove) => {
  const momentTimeUnlockedUntil = moment(record.timeUnlockedUntil);
  const timeUnlockedUntil = momentTimeUnlockedUntil.toDate().toLocaleDateString();
  const isLocked = momentTimeUnlockedUntil.isBefore(moment());

  return (
    <Box>
      {record.newTimeUnlockedUntil &&
        <Tooltip title={record.timeUnlockedUntil ? timeUnlockedUntil : "None" }>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ opacity: 0.6 }}>
              {moment(record.newTimeUnlockedUntil).toDate().toLocaleDateString()}
            </Box>
            <CloudSyncIcon color="primary" fontSize="small"/>
          </Box>
        </Tooltip>
      }
      {record.timeUnlockedUntil && !record.newTimeUnlockedUntil ?
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {timeUnlockedUntil}
          {isLocked ? <LockIcon color="error" fontSize="small"/> : <LockOpenIcon color="success" fontSize="small"/>}
        </Box> :
        null}
    </Box>
  );
};

export const StoveList: React.FC = () => {
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_READ_ONLY]);
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  const filters = [
    ...useCustomerFilters({}),
    ...useStoveFilters({
    }),
    stoveConnectionTypeFilter(),
    ...useDateRangeFilters({
      lowerBoundSource: "timeUnlockedUntilGreaterThan",
      lowerBoundLabel: "Unlocked Until (>=)",
      upperBoundSource: "timeUnlockedUntilLessThan",
      upperBoundLabel: "Unlocked Until (<=)"
    }),
    ...useDateRangeFilters({
      lowerBoundSource: "timeInactivatedGreaterThan",
      lowerBoundLabel: "Time repossessed (>=)",
      upperBoundSource: "timeInactivatedLessThan",
      upperBoundLabel: "Time repossessed (<=)"
    })
  ];

  const stoveIdRenderer = (stove: FullStove) => {
    const nftIcon = stove.nftEnabled ?
      <Tooltip title="This stove is enabled for NFT and working hard to reduce carbon dioxide emissions!">
        <Co2Icon color="info" fontSize="small"/>
      </Tooltip> :
      null;

    return <Box
      sx={{
        opacity: stove.status === "INACTIVE" ? 0.6 : 1,
        display: "flex",
        alignItems: "center",
        gap: 1
      }}>
      {stove.nativeId}{nftIcon}
    </Box>;
  };

  const renderCustomer = (record: FullStove) => {
    const customer = record.customer;
    return (hasAccess || !customer) ?
      customerRenderer(customer) :
      `${customer.firstName} ${customer.lastName}`;
  };

  return (
    <List filters={filters}
      actions={
        <PresetListActions selectColumnsEnabled>
          <ImportDeviceDataButton/>
        </PresetListActions>
      }
      pagination={<DefaultPagination/>}
      sort={{ field: "timeCreated", order: "DESC" }}
      perPage={25}
      empty={false}
      exporter={false}>
      <DatagridConfigurable
        bulkActionButtons={<StoveBulkActions/>}
        omit={["timeInactivated", "ixoAddress", "did", "nftId", "nftCollectionName"]}
        rowClick="show">
        <FunctionField label="Stove UID"
          sortable={true}
          sortBy={"nativeId"}
          render={(record: FullStove) => stoveIdRenderer(record)}/>
        <ReferenceField label="Device ID" reference="stoves/instances" source="instanceId" link={false}>
          <TextField source="deviceId"/>
        </ReferenceField>
        {isAdmin && <TextField label="Nft Collection Name" source="nftCollectionName" sortable={false}/>}
        {isAdmin && <TextField label="Nft Id" source="nftId" sortable={false}/>}
        {isAdmin && <TextField label="DID" source="did" sortable={false}/>}
        {isAdmin && <TextField label="Ixo Address" source="ixoAddress" sortable={false}/>}
        <FunctionField label="Model"
          render={(record: FullStove) => enumRenderer(record.model, StoveModel)}/>
        <FunctionField label="Connection Type"
          render={(record: FullStove) => enumRenderer(record.connectionType, ConnectionType)}/>
        <FunctionField label="Customer" render={renderCustomer} />
        <FunctionField label="Country"
          render={(record: FullStove) => countryNameRenderer(record)}/>
        <FunctionField label="Status"
          render={(record: FullStove) => enumIconRenderer(record.status, StoveStatus, stoveStatusIcons)}/>
        <FunctionField label="Unlocked until"
          sortable={true}
          sortBy={"timeUnlockedUntil"}
          render={renderUnlockedUntil}/>
        {
          hasAccess &&
          <ReferenceField label="Company" reference={Resources.Companies} source="companyId" sortable={false}>
            <TextField source="name"/>
          </ReferenceField>
        }
        <DateField label="Time registered" source="timeCreated" showTime/>
        <DateField label="Time repossessed" source="timeInactivated" showTime sortable={false}/>
      </DatagridConfigurable>
    </List>
  );
};
