import { CountryHavingObject } from "../../utils/commons";

export enum PaymentTransactionType {
  REPLENISHMENT = "Replenishment",
  WITHDRAWAL = "Withdrawal",
  REVERT = "Revert"
}

export type PaymentTransaction = {
  id: number,
  activeAssignment: boolean,
  amount: number,
  customerId: number,
  operationType: PaymentTransactionType,
  transactionId: string
};

export type Payment = {
  id: number,
  customerId?: number,
  telcoTransactionId: string,
  createdBy: number,
  telco: string,
  amount: number,
  currency: string,
  paymentReference: string,
  payer: string,
  bank: string,
  isRefunded: boolean,
  note: string,
  refundedBy: number,
  timePaid: string,
  transactions: PaymentTransaction[]
} & CountryHavingObject;
