import React from "react";

import Grid from "@mui/material/Grid";
import {
  BooleanField,
  Datagrid, DateField, FunctionField,
  ListContextProvider, ReferenceField,
  SimpleShowLayout, TextField,
  useList,
  useRecordContext
} from "react-admin";

import { PaymentTransaction, PaymentTransactionType } from "../../pages/payments/types";
import { Resources } from "../../resources";
import { Customer } from "../../utils/commons";
import { customerRenderer, EnumChipColors, enumChipRenderer } from "../../utils/field-renderers";

const paymentTransactionTypeChipColors: Record<keyof typeof PaymentTransactionType, EnumChipColors> = {
  REPLENISHMENT: "success",
  WITHDRAWAL: "info",
  REVERT: "warning"
};

export const sortPaymentTransactions = (transactions: PaymentTransaction[]) => {
  return transactions
    .sort((a: PaymentTransaction, b: PaymentTransaction) => (a.id > b.id ? -1 : 1));
};

export const PaymentTransactionsList: React.FC = () => {
  const currentPaymentRecord = useRecordContext();
  const sortedTransactions = sortPaymentTransactions(currentPaymentRecord?.transactions);

  const listContext = useList({ data: sortedTransactions });

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <SimpleShowLayout>
          <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="transactionId" label="Transaction ID" sortable={false}/>
              <FunctionField
                label="Transaction Type"
                sortable={false}
                render={(record: PaymentTransaction) =>
                  enumChipRenderer(record.operationType, PaymentTransactionType, paymentTransactionTypeChipColors)}/>
              <ReferenceField label="Customer" reference={Resources.Customers} source="customerId" link={false}>
                <FunctionField label="Customer"
                  render={(customer: Customer) => customerRenderer(customer)}/>
              </ReferenceField>
              <ReferenceField label="Device Id" reference={Resources.Stoves} source="stoveId" link="show">
                <TextField source="deviceId" label="Device Id"/>
              </ReferenceField>
              <FunctionField label="Amount" sortable={false} render={(record: PaymentTransaction) =>
                `${record.amount} ${currentPaymentRecord?.currency}`}/>
              <BooleanField label="Active Assignment" source="activeAssignment"/>
              <DateField source="operationDate" label="Creation date" showTime/>
            </Datagrid>
          </ListContextProvider>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
