import { CollectionPartner } from "../collections/types";

export type StockEvent = {
  id: number,
  collectionPartnerId: number,
  orderId: number,
  customerId: number,
  relatedCollectionPartnerId: number,
  stockItemId: number,
  itemName?: string,
  quantity: number,
  balanceBefore: number,
  balanceAfter: number,
  status: keyof typeof StockEventStatus,
  type: keyof typeof StockEventType,
  adjustmentReason: StockEventAdjustmentReason,
  note: string,
  updatedNote: string,
  driverName: string,
  updatedBy: number,
  createdBy: number
};

export enum StockEventStatus {
  IN_PROCESS = "In Process",
  COMPLETED = "Completed",
  CANCELED = "Cancelled"
}

export enum StockEventAdjustmentReason {
  THEFT = "Theft",
  DAMAGE = "Damage",
  REBATE = "Rebate",
  REBAGGING = "Rebagging",
  DEMONSTRATION = "Demonstration",
  WRITE_OFF = "Write-off",
  INTERNAL_USE = "Internal use",
  CANCELLATION = "Cancellation",
  WRONG_EVENT = "Wrong Event",
  DOUBLE_EVENT = "Double Event",
  OTHER_CANCELLATION = "Other Cancellation",
  OTHER = "Other"
}

export enum StockEventType {
  ADD = "Add",
  SELL = "Sell",
  TRANSFER_OUT= "Transfer (Out)",
  TRANSFER_IN = "Transfer (In)",
  ADJUSTMENT_INCREASE = "Adjustment (increase)",
  ADJUSTMENT_DECREASE = "Adjustment (decrease)",
  STOCK_COUNT = "Stock Count"
}

export const { TRANSFER_IN, SELL, ...AddStockEventType } = StockEventType;

export const formatCollectionName = (collection: CollectionPartner): string => {
  return collection ? `${collection.code} - ${collection.name}` : "-";
};
