import React, { ReactElement, useState } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Box, Tooltip } from "@mui/material";
import { isNil } from "lodash-es";
import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField, useListContext
} from "react-admin";

import { useCustomerFilters } from "../../components/filter/customer-filters";
import { stoveConnectionTypeFilter, useStoveFilters } from "../../components/filter/stove-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { Resources } from "../../resources";
import { Customer } from "../../utils/commons";
import { customerRenderer } from "../../utils/field-renderers";
import { FullStove } from "../stoves/StoveShow";
import PerformanceBulkActions from "./bulk-actions/PerformanceBulkActions";
import { useProfilePerformanceFilters } from "./filters";
import { HighlightWarningButton } from "./HighlightWarningButton";
import { connectivityLevelRender } from "./renderers";
import { ProfilePerformance } from "./types";
import { freshdeskTicketColumnRenderer } from "./utils";

const Okay: React.FC = () => <CheckCircleIcon color="success" fontSize="small"/>;
const QuestionMark: React.FC = () => <HelpIcon color="secondary" fontSize="small"/>;
const ErrorWarning: React.FC = () => <ReportProblemIcon color="warning" fontSize="small"/>;
const ErrorCritical: React.FC = () => <ErrorIcon color="error" fontSize="small"/>;

const cookingActivityRenderer = (record: ProfilePerformance) => {
  if (isNil(record.totalCookingSessionsCount) || isNil(record.totalCookingSessionsDuration)) return null;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
      <Box>{record.totalCookingSessionsCount ?? 0} times</Box>
      <Box>{record.totalCookingSessionsDurationFormatted}</Box>
    </Box>
  );
};

const renderTooltipLabel = (label: string | ReactElement, tooltip: string | ReactElement) => {
  return (
    <Tooltip title={tooltip} placement="top">
      <Box>{label}</Box>
    </Tooltip>
  );
};

const PAYMENT_ARREARS_DAYS = 10;
const CONNECTIVITY_MAX_INACTIVITY = 9;
const COOKING_MAX_INACTIVITY = 5;

type Props = {
  isHighlighted: boolean
};
export const PerformanceListGrid: React.FC<Props> = ({ isHighlighted }) => {
  const { data } = useListContext();
  const arrearsRenderer = (value: number) => {
    const isNegative = value < 0;
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box sx={{ minWidth: "30px", opacity: isNegative ? 0.6 : 1 }}>{value}</Box>
        {isHighlighted && !isNil(value) &&
              ((isNegative || value < PAYMENT_ARREARS_DAYS) ?
                <Okay/> :
                value > PAYMENT_ARREARS_DAYS * 2 ? <ErrorCritical/> : <ErrorWarning/>
              )
        }
      </Box>
    );
  };

  const lastCookingRenderer = (performance: ProfilePerformance) => {
    const lastConnectivityDaysAgo = performance.lastConnectivityDaysAgo;
    const lastCookingDaysAgo = performance.lastCookingDaysAgo;
    if (isNil(lastConnectivityDaysAgo) || isNil(lastCookingDaysAgo)) return null;

    const isConnectivityViolated = lastConnectivityDaysAgo > CONNECTIVITY_MAX_INACTIVITY;
    const isViolated = !isConnectivityViolated && lastCookingDaysAgo > COOKING_MAX_INACTIVITY;
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box sx={{ minWidth: "30px" }}>{lastCookingDaysAgo}</Box>
        {isHighlighted &&
              (!isViolated ?
                isConnectivityViolated ? <QuestionMark/> : <Okay/> :
                lastCookingDaysAgo > COOKING_MAX_INACTIVITY * 2 ? <ErrorCritical/> :
                  <ErrorWarning/>)}
      </Box>
    );
  };
  return (<DatagridConfigurable
    isRowSelectable={(record: ProfilePerformance) => !isNil(record.customerId)}
    bulkActionButtons={<PerformanceBulkActions/>}
  >
    <ReferenceField label="Device Id" reference={Resources.Stoves} source="stoveId" link="show">
      <TextField source="deviceId" label="Device Id"/>
    </ReferenceField>
    <NumberField label="Last connectivity, days" source="lastConnectivityDaysAgo"/>
    <ReferenceField reference={Resources.Stoves}
      source="stoveId"
      label={renderTooltipLabel("Connectivity Level",
        <Box>
          This represents the connectivity level of the stove, which is determined by the number
          of days since the stove last connected to the server<br/>
          <strong>&gt;= 0 and &lt;= 1 days</strong> - High<br/>
          <strong>&gt;= 2 and &lt;= 4 days</strong> - Medium<br/>
          <strong>&gt;= 5 and &lt;= 30 days</strong> - Low<br/>
          <strong>&gt; 30 days or empty</strong> - Zero
        </Box>
      )}>
      <FunctionField
        render={(record: FullStove) => {
          if (record.connectionType === "NOT_CONNECTED") {
            return null;
          }
          const performance = data.find((item: ProfilePerformance) => item.stoveId === record.id);
          if (!performance) {
            return null;
          }

          return connectivityLevelRender(performance);
        }}
      />
    </ReferenceField>
    <ReferenceField label="Customer" reference={Resources.Customers} source="customerId" sortable={false}>
      <FunctionField render={(customer: Customer) => customerRenderer(customer)}/>
    </ReferenceField>
    <NumberField source="totalPelletsBagsSize"
      label={renderTooltipLabel("Pellets sum, kg",
        "The total amount of pellets the customer has bought since the registration date")
      }/>
    <FunctionField
      sortable
      sortBy="totalPelletsPurchaseOverdueDays"
      render={(performance: ProfilePerformance) => arrearsRenderer(performance.totalPelletsPurchaseOverdueDays)}
      label={
        renderTooltipLabel(
          "Arrears (total), days",
          <Box>
                The number of days the customer either has in reserve (greyed out) or in
                arrears based on the
                contract agreement (1 kg/day) since the registration date.<br/>
            <strong>&gt; {PAYMENT_ARREARS_DAYS} days</strong> - warning<br/>
            <strong>&gt; {PAYMENT_ARREARS_DAYS * 2} days</strong> - critical
          </Box>
        )}
    />
    <FunctionField
      sortable
      sortBy="lastPelletsPurchaseOverdueDays"
      render={(performance: ProfilePerformance) => arrearsRenderer(performance.lastPelletsPurchaseOverdueDays)}
      label={
        renderTooltipLabel(
          "Arrears (last), days",
          <Box>
                The number of days the customer either has in reserve (greyed out) or in
                arrears based on
                the contract agreement (1 kg/day) since the LAST purchase. <br/>
            <strong>&gt; {PAYMENT_ARREARS_DAYS} days</strong> - warning<br/>
            <strong>&gt; {PAYMENT_ARREARS_DAYS * 2} days</strong> - critical
          </Box>
        )}
    />
    <FunctionField
      sortable
      sortBy="totalCookingSessionsDuration"
      render={cookingActivityRenderer}
      label={
        renderTooltipLabel(
          "Cooking activity",
          "The cooking activity of the customer for the preceding 30 days before the last connection. I.e. if the last time the stove connected to the server was 5 days ago, this column will show the activity for the last 35 days since now. Total cooking sessions and duration, respectively.")}
    />
    <FunctionField
      sortable
      sortBy="lastCookingDaysAgo"
      render={(performance: ProfilePerformance) => lastCookingRenderer(performance)}
      label={renderTooltipLabel(
        "Last cooking, days",
        <Box>
              The number of days since the last KNOWN cooking activity<br/>
          <strong>&gt; {COOKING_MAX_INACTIVITY} days</strong> - warning<br/>
          <strong>&gt; {COOKING_MAX_INACTIVITY * 2} days</strong> - critical
        </Box>
      )}
    />
    <FunctionField label="Freshdesk tickets"
      render={(record: ProfilePerformance) => freshdeskTicketColumnRenderer(record.ticketStatistics)}/>
    <ReferenceField
      sortable
      sortBy="timeCustomerRegistered"
      label="Customer registration date"
      reference={Resources.Customers}
      source="customerId">
      <DateField source="timeRegistered" showTime/>
    </ReferenceField>
  </DatagridConfigurable>);
};

export const ProfilePerformanceList: React.FC = () => {
  const [isHighlighted, setIsHighlighted] = useState(true);
  const filters = [
    ...useCustomerFilters({
      countriesAlwaysOn: false
    }),
    ...useStoveFilters({
      stoveUidsAlwaysOn: false,
      deviceIdsAlwaysOn: false
    }),
    stoveConnectionTypeFilter(),
    ...useProfilePerformanceFilters()
  ];

  return (
    <List
      filters={filters}
      actions={
        <PresetListActions selectColumnsEnabled>
          <HighlightWarningButton onClick={() => setIsHighlighted((prevState) => !prevState)}/>
        </PresetListActions>
      }
      pagination={<DefaultPagination/>}
      perPage={25}
      exporter={false}
      sort={{ field: "timeCustomerRegistered", order: "DESC" }}>
      <PerformanceListGrid isHighlighted={isHighlighted}/>
    </List>
  );
};
