import React, { useEffect, useState } from "react";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid";
import { maxLength } from "ra-core";
import {
  AutocompleteInput,
  minValue,
  NumberInput,
  required, SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar, useDataProvider, useNotify
} from "react-admin";
import { useWatch } from "react-hook-form";

import { CollectionPartner } from "../../../components/collections/types";
import { AddStockEventType, StockEventAdjustmentReason } from "../../../components/stock-events/types";
import { StockItem } from "../../../components/stock-item/types";
import { Resources } from "../../../resources";
import { CollectionPartnerGroupedSelect } from "./CollectionPartnerGroupedSelect";

type ToolbarProps = {
  isSaveButtonDisabled: boolean,
  handleClose: () => void
};

type CreateStockEventDialogProps = {
  onClose: () => void,
  onConfirm: (data: any) => void,
  isProcessing: boolean,
  isOpened: boolean,
  collectionPartners: CollectionPartner[]
};

type StockItemInputProps = {
  stockItems: StockItem[]
};

const ADJUSTMENT_REASONS_NOT_ALLOWED_FOR_MANUAL_CREATION =
    [StockEventAdjustmentReason.CANCELLATION,
      StockEventAdjustmentReason.WRONG_EVENT,
      StockEventAdjustmentReason.DOUBLE_EVENT,
      StockEventAdjustmentReason.OTHER_CANCELLATION];

const STOCK_EVENT_TYPES_FOR_MANUAL_CREATION =
    Object.entries(AddStockEventType)
      .map(([key, value]) => ({ id: key, name: value }));

const STOCK_EVENT_ADJUSTMENT_REASONS_FOR_MANUAL_CREATION =
    Object.entries(StockEventAdjustmentReason)
      .filter(([, value]) => !ADJUSTMENT_REASONS_NOT_ALLOWED_FOR_MANUAL_CREATION.includes(value))
      .map(([key, value]) => ({ id: key, name: value }));

const StockEventCreationToolbar: React.FC<ToolbarProps> = ({ handleClose, isSaveButtonDisabled }) => {
  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item><SaveButton disabled={isSaveButtonDisabled}/></Grid>
        <Grid item>
          <Button onClick={handleClose} variant="outlined">
              Cancel
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const AdjustmentReasonInput = () => {
  const type = useWatch({ name: "type" });

  return type === "ADJUSTMENT_INCREASE" || type === "ADJUSTMENT_DECREASE" ? (
    <SelectInput source="adjustmentReason" fullWidth validate={[required()]} choices={STOCK_EVENT_ADJUSTMENT_REASONS_FOR_MANUAL_CREATION}/>
  ) : null;
};

const QuantityInput = () => {
  const type = useWatch({ name: "type" });
  const quantityMinValue = type === "STOCK_COUNT" ? 0 : 1;

  return (
    <NumberInput name="quantity" source="quantity" validate={[required(), minValue(quantityMinValue)]} fullWidth/>
  );
};

const StockItemInput: React.FC<StockItemInputProps> = ({ stockItems }) => {
  const selectedCollectionPartnerType = useWatch({ name: "selectedCollectionPartnerType" });
  const selectedRelatedCollectionPartnerType = useWatch({ name: "selectedRelatedCollectionPartnerType" });
  const selectedRelatedCollectionPartnerCountry = useWatch({ name: "selectedRelatedCollectionPartnerCountry" });
  const selectedCollectionPartnerCountry = useWatch({ name: "selectedCollectionPartnerCountry" });
  const filteredStockItems = stockItems.filter((stockItem) =>
    stockItem.availableFor.includes(selectedCollectionPartnerType) &&
      (stockItem.restrictionCountries.length === 0
          || stockItem.restrictionCountries.includes(selectedCollectionPartnerCountry)) &&
      (!selectedRelatedCollectionPartnerType
          || stockItem.availableFor.includes(selectedRelatedCollectionPartnerType)) &&
      (!selectedRelatedCollectionPartnerCountry ||
          (stockItem.restrictionCountries.length === 0
              || stockItem.restrictionCountries.includes(selectedRelatedCollectionPartnerCountry)))
  );
  return (<AutocompleteInput
    size="small"
    source="stockItemId"
    choices={filteredStockItems
      .map((stockItem) => ({ id: stockItem.id, name: stockItem.name }))
    }
    fullWidth
  />);
};

export const CreateStockEventDialog: React.FC<CreateStockEventDialogProps> = ({
  onClose,
  onConfirm,
  isProcessing,
  isOpened,
  collectionPartners
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [stockItems, setStockItems] = useState<StockItem[]>([]);

  useEffect(() => {
    dataProvider.getList<StockItem>(Resources.StockItems, {
      pagination: {
        page: 1,
        perPage: 500
      },
      sort: {
        field: "name",
        order: "ASC"
      },
      filter: { statuses: ["ACTIVE"] }
    }).then(( response ) => {
      setStockItems(response.data);
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve stock items", { type: "error" });
    });
  }, [dataProvider, notify]);

  return <Dialog
    fullWidth
    maxWidth="md"
    open={isOpened}
    onClose={onClose}
  >
    <DialogTitle>Create Stock Event</DialogTitle>
    <DialogContent>
      <Grid item xs={12}>
        <SimpleForm toolbar={<StockEventCreationToolbar handleClose={onClose}
          isSaveButtonDisabled={isProcessing}/>}
        onSubmit={onConfirm}>
          <CollectionPartnerGroupedSelect
            source="collectionPartnerId"
            formFieldToFilterOut="relatedCollectionPartnerId"
            collectionPartners={collectionPartners}
            onlyForTransferType={false}
          />
          <SelectInput source="type" fullWidth validate={[required()]} choices={STOCK_EVENT_TYPES_FOR_MANUAL_CREATION}/>
          <CollectionPartnerGroupedSelect
            labelPrefix="Destination"
            source="relatedCollectionPartnerId"
            formFieldToFilterOut="collectionPartnerId"
            collectionPartners={collectionPartners}
            onlyForTransferType={true}
          />
          <AdjustmentReasonInput/>
          <StockItemInput stockItems={stockItems}/>
          <QuantityInput/>
          <TextInput name="note" source="note" validate={[maxLength(255)]} multiline fullWidth/>
        </SimpleForm>
      </Grid>
    </DialogContent>
  </Dialog>;
};