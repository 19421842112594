import React from "react";

import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  NumberField,
  TextField,
  useList
} from "react-admin";

import { StockEventCollectionPartnerReferenceField } from "../../pages/stock-events/StockEventsList";
import { enumRenderer } from "../../utils/field-renderers";
import { StockEvent, StockEventType } from "./types";

type StockEventSummaryDialogProps = {
  stockEvents: StockEvent[],
  fieldsToIgnore?: string[]
};

export const StockEventSummaryDialog: React.FC<StockEventSummaryDialogProps> = ({
  stockEvents,
  fieldsToIgnore = []
}) => {
  const listContext = useList({ data: [...stockEvents] });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        {!fieldsToIgnore.includes("id") && <TextField source="id" label="Event ID" sortable={false}/>}
        <StockEventCollectionPartnerReferenceField label="Collection" source="collectionPartnerId"/>
        <StockEventCollectionPartnerReferenceField label="Related Collection" source="relatedCollectionPartnerId"/>
        <TextField source="itemName" label="Item Name" sortable={false}/>
        <NumberField source="quantity" label="Quantity" sortable={false}/>
        <FunctionField
          label="Type"
          sortable={false}
          render={(event: StockEvent) => enumRenderer(event.type, StockEventType)}/>
        <TextField source="note" label="Note" sortable={false}/>
      </Datagrid>
    </ListContextProvider>
  );
};