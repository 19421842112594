import React, { useEffect, useState } from "react";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Button, Tooltip } from "@mui/material";
import { BulkDeleteWithConfirmButton } from "react-admin";

import { UserRoles } from "../../../core/providers/auth/roles";
import { Stove } from "../../../utils/commons";
import { useCheckAccess } from "../../../utils/use-check-access";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { LockStoveAction } from "./LockStoveAction";

const StoveBulkActions = () => {
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN]);
  const [isLockDialogOpen, setIsLockDialogOpen] = useState(false);
  const [isNonConnectedStoveSelected, setIsNonConnectedStoveSelected] = useState(false);
  const [isNonPcbStoveSelected, setIsNonPcbStoveSelected] = useState(false);
  const { data } = usePageSafeSelector<Stove>();

  useEffect(() => {
    setIsNonConnectedStoveSelected(data.some((stove) => stove.connectionType === "NOT_CONNECTED"));
    setIsNonPcbStoveSelected(data.some((stove) => stove.model !== "PCB"));
  }, [data]);

  return (
    <>
      <Tooltip title="Lock or unlock stoves">
        <span>
          <Button
            color="error"
            disabled={!hasAccess || isNonConnectedStoveSelected}
            onClick={() => setIsLockDialogOpen(true)}
            startIcon={<LockOpenIcon/>}>
              Lock/Unlock
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={"Delete stoves"}>
        <BulkDeleteWithConfirmButton
          disabled={!hasAccess || isNonPcbStoveSelected}
          mutationMode="pessimistic"
          size="medium"/>
      </Tooltip>
      <LockStoveAction
        isDialogOpen={isLockDialogOpen}
        onDialogClose={() => setIsLockDialogOpen(false)}
      />
    </>
  );
};

export default StoveBulkActions;
