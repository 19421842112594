import React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button } from "@mui/material";
import { constant } from "lodash-es";

import deviceDataTemplate from "../../../assets/templates/device_data_template.csv";
import { ImportDataButton } from "../../../components/list/import/ImportDataButton";
import { UserRoles } from "../../../core/providers/auth/roles";
import { SECONDARY_COLOR } from "../../../core/theme/theme";
import { useCheckAccess } from "../../../utils/use-check-access";

export const ImportDeviceDataButton: React.FC = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  return (
    <ImportDataButton
      resource={constant("stoves/instances")}
      disabled={!hasAccess}
      buttonLabel="Import Device data"
      buttonTooltip="Upload Device-related data (UID mappings, country, etc.)"
      dialogTitle="Import Device data"
      dialogContentText={
        <>
          <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
            This feature allows you to upload Device-related data (UID mappings, country, etc.).
            Below you can download the CSV file template containing sample data.
          </Box>
          <Box sx={{ mb:2 }}>
            <Button
              sx={{
                textTransform: "none",
                fontSize: 14,
                fontWeight: 600,
                letterSpacing: "0.25px"
              }}
              variant="text"
              startIcon={<DownloadIcon/>}
              href={deviceDataTemplate}
              download="example.csv"
            >
              Download template
            </Button>
          </Box>
        </>
      }
      reprocessFileExportHeader="Device Id,UID,Model,Connection Type,Country,Year of Manufacture,Remapping type"
      reprocessDialogTitle="Remap Device data"
      reprocessDialogContentText={
        <>
          <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
            <Box>
              {'Below, you can download a file with stoves that are already mapped and fill in the "Remapping type" column: '}
            </Box>
            <Box>
              {'Possible values are "FIX_UID", "FIX_DEVICE", "CHANGE_UID", "CHANGE_DEVICE"'}
            </Box>
          </Box>
        </>
      }
    />
  );
};