import * as React from "react";
import { useEffect, useState } from "react";

import HelpIcon from "@mui/icons-material/Help";
import { Box, Tooltip } from "@mui/material";
import {
  AutocompleteInput,
  BooleanInput, GetListParams,
  maxLength,
  minLength,
  minValue,
  number,
  NumberInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar, useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect
} from "react-admin";

import { StockItem } from "../../components/stock-item/types";
import { Resources } from "../../resources";
import { Country, CustomerGroupType } from "../../utils/commons";
import { Offer, OfferStatus, OfferType } from "./types";

const nameValidation = [required(), minLength(3), maxLength(250)];
const priceValidation = [required(), number(), minValue(0)];
const countryValidation = [required()];
const typeValidation = [required()];
const availableForValidation = [required()];
const statusValidation = [required()];

const typeChoices = Object.entries(OfferType).map(([key, value]) => ({ id: key, name: value }));
const countryChoices = Object.entries(Country).map(([key, value]) => ({ id: key, name: value }));
const statusChoices = Object.entries(OfferStatus).map(([key, value]) => ({ id: key, name: value }));
const availableForChoices = Object.entries(CustomerGroupType).map(([key, value]) => ({ id: key, name: value }));

export enum OperationType {
  CREATE,
  UPDATE
}

type Props = {
  operationType: OperationType,
  submit: (resource: Resources, data: any, options: any) => void
};

export const OffersForm: React.FC<Props> = ({
  operationType,
  submit
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext<Offer>();
  const [offerType, setOfferType] = useState<keyof typeof OfferType | undefined>(record?.offerType);
  const [country, setCountry] = useState<string | undefined>(record?.country);
  const [stockItems, setStockItems] = useState<StockItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const idParams = record ? { id: record.id } : {};
  const isCreate = operationType === OperationType.CREATE;

  useEffect(() => {
    const params = { pagination: { page: 1, perPage: 1000 } } as GetListParams;
    dataProvider.getList<StockItem>(Resources.StockItems, params)
      .then((response) => {
        setStockItems(response.data);
        return response;
      });
  }, [dataProvider]);
  
  const submitForm = (data: any) => {
    setIsLoading(true);
    submit(Resources.Offers, { ...idParams, data }, {
      onError: (error: any) => {
        setIsLoading(false);
        const errorCode = error.body?.errorCode;
        if (errorCode && errorCode === "offer.same_price_and_bag_size_already_exists") {
          notify("An offer with that price and bag size already exists", { type: "error" });
        } else {
          notify("The internal server error occurred", { type: "error" });
        }
      },
      onSuccess: () => {
        setIsLoading(false);
        redirect("list", Resources.Offers);
        notify("The offer form has been submitted");
      }
    });
  };

  return (
    <SimpleForm
      sx={{ maxWidth: 600 }}
      toolbar={<Toolbar><SaveButton disabled={isLoading}/></Toolbar>}
      sanitizeEmptyValues
      warnWhenUnsavedChanges
      defaultValues={() => ({ isAutoResolvable: true })}
      onSubmit={submitForm}>
      <TextInput source="name" validate={nameValidation} fullWidth/>
      <SelectInput
        source="country"
        disabled={!isCreate}
        choices={countryChoices}
        validate={countryValidation}
        onChange={(event) => setCountry(event.target.value)}
        fullWidth/>
      <SelectInput
        source="offerType"
        disabled={!isCreate}
        choices={typeChoices}
        validate={typeValidation}
        onChange={(event) => setOfferType(event.target.value as keyof typeof OfferType)}
        fullWidth
      /> {
        offerType && offerType !== "CONTRACT" && (
          <AutocompleteInput
            size="small"
            source="stockItemId"
            choices={stockItems
              .filter((stockItem) => stockItem.type === offerType)
              .filter((stockItem) => stockItem.restrictionCountries.length === 0 ||
                  country && stockItem.restrictionCountries.includes(country))
              .map((stockItem) => ({ id: stockItem.id, name: stockItem.name }))
            }
            fullWidth
          />
        )
      }
      <NumberInput source="price" validate={priceValidation} fullWidth/>
      <SelectArrayInput
        source="availableFor"
        label="Available for"
        choices={availableForChoices}
        validate={availableForValidation}
        fullWidth
      />
      {!isCreate &&
          <SelectInput
            source="offerStatus"
            choices={statusChoices}
            validate={statusValidation}
            fullWidth/>
      }
      {
        offerType === "PELLETS" && (
          <>
            <BooleanInput
              label={
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>Automatically resolvable</Box>
                  <Tooltip title="When activated, the offer will be considered for automatic order creation. This only applies to Utility and Reseller customers.">
                    <HelpIcon color="secondary" fontSize="small"/>
                  </Tooltip>
                </Box>
              }
              source="isAutoResolvable"
              defaultValue={true}
              fullWidth/>
            <BooleanInput
              disabled={!isCreate}
              label={
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>Rebated</Box>
                  <Tooltip title="Turning this on makes it eligible only for fully paid Non-Utility customers">
                    <HelpIcon color="secondary" fontSize="small"/>
                  </Tooltip>
                </Box>
              }
              source="isRebated"
              fullWidth/>
          </>
        )
      }

    </SimpleForm>
  );
};