export type Ticket = {
  id: number,
  freshdeskTicketId: number,
  resourceId: number,
  status: string,
  category: TicketCategory
};

export enum TicketCategory {
  STOVE_CONNECTIVITY = "STOVE_CONNECTIVITY",
  INSUFFICIENT_COOKING_ACTIVITY = "INSUFFICIENT_COOKING_ACTIVITY",
  LAST_PAYMENT_IN_ARREARS = "LAST_PAYMENT_IN_ARREARS",
  TOTAL_PAYMENT_IN_ARREARS = "TOTAL_PAYMENT_IN_ARREARS",
  OTHER = "OTHER"
}

export enum ConnectivityLevel {
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
  ZERO = "Zero"
}
export const ConnectivityLevelColor: Record<keyof typeof ConnectivityLevel, string> = {
  HIGH: "green",
  MEDIUM: "orange",
  LOW: "red",
  ZERO: "black"
};

export enum OwnershipFilter {
  CUSTOMERS_WITH_STOVES = "Customers with stoves",
  CUSTOMERS_WITHOUT_STOVES = "Customers without stoves",
  STOVES_WITHOUT_CUSTOMERS = "Stoves without customers",
}
export type TicketStatistics = {
  totalTickets: number,
  activeTickets: Ticket[]
};

export type ProfilePerformance = {
  id: number,
  customerId?: number,
  totalPelletsBagsSize: number,
  totalPelletsPurchaseOverdueDays: number,
  lastPelletsPurchaseOverdueDays: number,
  stoveId?: number,
  connectivityLevel?: ConnectivityLevel,
  totalCookingSessionsCount?: number,
  totalCookingSessionsDuration?: number,
  totalCookingSessionsDurationFormatted?: string,
  lastCookingDaysAgo?: number,
  lastConnectivityDaysAgo?: number,
  ticketStatistics: TicketStatistics
};

export type TicketStatus = {
  id: number,
  name: string
};