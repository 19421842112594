import * as React from "react";

import { Show, Tab, TabbedShowLayout, useShowContext } from "react-admin";

import { Customer, Stove } from "../../utils/commons";
import { StoveMainTab } from "./tabs/StoveMainTab";
import { StoveUsageTab } from "./tabs/StoveUsageTab";

export type FullStove = Stove & {
  customer: Customer
};

export const StoveShow: React.FC = () => {

  const StoveShowLayout = () => {
    const { record: stove } = useShowContext<FullStove>();

    return (
      <TabbedShowLayout>
        <Tab label="Main">
          <StoveMainTab/>
        </Tab>
        <Tab label="Usage" path="usage">
          <StoveUsageTab stoves={stove && [stove]}/>
        </Tab>
      </TabbedShowLayout>
    );
  };

  return (
    <Show queryOptions={{ cacheTime: 0, staleTime: 0 }}>
      <StoveShowLayout/>
    </Show>
  );
};
