import React from "react";

import { Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  BooleanField,
  DateField,
  FunctionField,
  SimpleShowLayout,
  TextField,
  useShowController
} from "react-admin";

import { Customer, CustomerGroupType, CustomerStatus, FullCustomer } from "../../../utils/commons";
import {
  capitalizeRenderer,
  countryNameRenderer,
  customerRenderer,
  enumRenderer, geolocationRenderer
} from "../../../utils/field-renderers";
import { CreateOrUpdateOrderButton } from "../../orders/actions/create/CreateOrUpdateOrderButton";
import { customerBalanceRenderer } from "../renderers";

const locationHierarchyRenderer = (customer: FullCustomer) => {
  return customer.locationsHierarchy?.map((l) => l.name).join(" > ") || "-";
};

const phoneNumbersRenderer = (customer: FullCustomer) => {
  return (customer.phoneNumbers || [])
    .sort((phoneNumber) => phoneNumber.isPrimary ? -1 : 1)
    .map((phoneNumber) => phoneNumber.number + (phoneNumber.isPrimary ? " (primary)" : ""))
    .join(", ");
};

export const CustomerMainTab: React.FC = () => {
  const { record: customer } = useShowController<FullCustomer>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <FunctionField label="Name"
            render={(customer: FullCustomer) => customerRenderer(customer)}/>
          <TextField source="contractReference"/>
          <FunctionField label="Type"
            render={(customer: FullCustomer) => capitalizeRenderer(customer.type)}/>
          <FunctionField label="Group"
            render={(customer: FullCustomer) => enumRenderer(customer.groupType, CustomerGroupType)}/>
          <FunctionField label="State"
            render={(customer: FullCustomer) => enumRenderer(customer.customerStatus, CustomerStatus)}/>
          <BooleanField label="Fully Paid" source="isFullyPaid"/>
          <TextField source="gender"/>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <FunctionField label="Country"
            render={(customer: Customer) => countryNameRenderer(customer)}/>
          <FunctionField label="Location"
            render={(customer: FullCustomer) => locationHierarchyRenderer(customer)}/>
          <TextField source="address" label="Text address" emptyText="-"/>
          <FunctionField label="Geolocation"
            render={(customer: FullCustomer) => geolocationRenderer(customer) || "-"}/>
          <FunctionField label="Phone numbers"
            render={(customer: FullCustomer) => phoneNumbersRenderer(customer)}/>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <DateField source="timeRegistered" label="Registration time" showTime/>
          <DateField source="timeInactivated" label="Reposession time" showTime emptyText="n/a"/>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <FunctionField label="Balance"
            render={(customer: FullCustomer) => customerBalanceRenderer(customer)}/>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ px: 2, py: 1 }}>
          <CreateOrUpdateOrderButton
            variant="outlined"
            predefinedCustomer={customer}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
