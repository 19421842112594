import React from "react";

import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from "react-admin";

import { useCustomerFilters } from "../../components/filter/customer-filters";
import { useStoveFilters } from "../../components/filter/stove-filters";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { Resources } from "../../resources";
import { SamplingsListActions } from "./SamplingsListActions";

export const SamplingsList: React.FC = () => {
  const filters = [
    ...useCustomerFilters({}),
    ...useStoveFilters({
    }),
    ...useDateRangeFilters({})
  ];

  return (
    <List filters={filters}
      exporter={false}
      empty={false}
      pagination={<DefaultPagination/>}
      actions={<SamplingsListActions/>}
      perPage={25}
      sort={{ field: "timeCreated", order: "DESC" }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id"/>
        <ReferenceField label="Stove UID" reference={Resources.Stoves} source="stoveId" link="show">
          <TextField source="nativeId"/>
        </ReferenceField>
        <TextField source="cookingSessionId"/>
        <TextField source="eventType" sortable={false}/>
        <NumberField source="fanSpeed1" label="Fan speed" sortable={false}/>
        <NumberField source="batteryVoltage" sortable={false}/>
        <NumberField source="latitude" sortable={false}/>
        <NumberField source="longitude" sortable={false}/>
        <NumberField source="worktime" sortable={false}/>
        <DateField source="timeRecorded" label="Time recorded" showTime/>
        <DateField source="timeCreated" label="Time saved" showTime/>
      </Datagrid>
    </List>
  );
};
