import React from "react";

import { Chip } from "@mui/material";
import { isNil } from "lodash-es";

import { enumRenderer } from "../../utils/field-renderers";
import { ConnectivityLevel, ConnectivityLevelColor, ProfilePerformance } from "./types";

type CustomChipProps = {
  label: string,
  textColor: string
};

const CustomChip: React.FC<CustomChipProps> = ({ label, textColor }) => {
  return (
    <Chip
      label={label}
      sx={{ border: 1, color: textColor }}
    />
  );
};

export const connectivityLevelRender = (record: ProfilePerformance) => {
  if (isNil(record.stoveId) || isNil(record.connectivityLevel)) {
    return <></>;
  }

  const level = record.connectivityLevel;
  const color = ConnectivityLevelColor[level.toUpperCase() as keyof typeof ConnectivityLevelColor];
  const label = enumRenderer(level, ConnectivityLevel);
  if (!label) return (<></>);
  return (
    <CustomChip
      textColor={color}
      label={label}
    />
  );
};